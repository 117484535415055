<template>
    <div class="container-fluid">
        <div class="text-center mt-4 mb-3 font-weight-bold" style="font-size: 24px;">
            <span>ลงทะเบียนผู้ใช้</span>
        </div>
        <div class="mt-4 text-right" style="font-size: 16px;">
            <span style="margin-right: 17%">พบปัญหาการลงทะเบียนติดต่อ : 02-5901741</span>
        </div>
        <b-row>
            <b-col sm="0" md="1" lg="2" xl="2"></b-col>
            <b-col sm="12" md="10" lg="8" xl="8">
                <div class="main-form-oscc">
                    <b-row>
                        <b-col class="header-form-oscc mr-3 ml-3">
                            ข้อมูลผู้ใช้
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input0"
                                    class="mb-0"
                            >
                                <template #label>
                                    ประเภทผู้ใช้งาน<span class="star-red">*</span>
                                </template>
                                <b-form-select v-model="register.role" class="form-control" id="input0" :options="options_role"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <template v-if="register.role == 2">
                            <b-col cols="6" class="body-form-oscc">
                                <b-form-group
                                        label-for="input00"
                                        class="mb-0"
                                >
                                    <template #label>
                                        จังหวัด
                                    </template>
                                    <b-form-select text-field="name" value-field="code" v-model="register.province" class="form-control" id="input00" :options="options_province"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </template>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input1"
                                    class="mb-0"
                            >
                                <template #label>
                                    รหัสหน่วยงาน (5 หลัก)<span class="star-red">*</span>
                                </template>
                                <b-form-input
                                        id="input1"
                                        v-model="register.agency_code"
                                        v-on:input="getHospitalName"
                                        v-on:keypress="NumbersOnly"
                                        :maxlength="5"
                                        class="input-form-oscc"
                                        placeholder="ระบุรหัสหน่วยงาน"
                                        type="text"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input2"
                                    class="mb-0"
                            >
                                <template #label>
                                    ชื่อหน่วยงาน
                                </template>
                                <b-form-input
                                        id="input2"
                                        v-model="register.agency_name"
                                        readonly
                                        class="input-form-oscc"
                                        placeholder="ระบุชื่อหน่วยงาน"
                                        type="text"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
<!--                    <b-row>-->
<!--                        <b-col cols="6" class="body-form-oscc">-->
<!--                            <b-form-group-->
<!--                                    label-for="input3"-->
<!--                                    class="mb-0"-->
<!--                            >-->
<!--                                <template #label>-->
<!--                                    ชื่อผู้ใช้<span class="star-red">*</span>-->
<!--                                </template>-->
<!--                                <b-form-input-->
<!--                                        id="input3"-->
<!--                                        v-model="register.username"-->
<!--                                        class="input-form-oscc"-->
<!--                                        placeholder="ระบุชื่อผู้ใช้"-->
<!--                                        type="text"-->
<!--                                ></b-form-input>-->
<!--                            </b-form-group>-->
<!--                        </b-col>-->
<!--                        <b-col cols="6" class="body-form-oscc">-->

<!--                        </b-col>-->
<!--                    </b-row>-->
                  <b-row>
                    <b-col cols="6" class="body-form-oscc pt-0">
                      <b-form-group
                          label-for="input6"
                          class="mb-0"
                      >
                        <template #label>
                          อีเมล<span class="star-red">*</span>
                        </template>
                        <b-form-input
                            id="input6"
                            v-model="register.email"
                            class="input-form-oscc"
                            placeholder="ระบุอีเมล"
                            :state="isEmailValid"
                            type="email"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-live-feedback">
                          ระบุอีเมล
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6" class="body-form-oscc pt-0">
                      <b-form-group
                          label-for="input7"
                          class="mb-0"
                      >
                        <template #label>
                          เลขบัตรประจำตัวประชาชน<span class="star-red">*</span>
                        </template>
                        <b-form-input
                            id="input7"
                            v-model="register.id_card"
                            class="input-form-oscc"
                            placeholder="ระบุเลขบัตรประจำตัวประชาชน"
                            :state="isIDCardValid"
                            :maxlength="13"
                            v-on:keypress="NumbersOnly"
                            type="text"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-live-feedback">
                          ระบุเลขบัตรประจำตัวประชาชน 13 หลัก
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input4"
                                    class="mb-0"
                            >
                                <template #label>
                                    รหัสผ่าน<span class="star-red">*</span>
                                </template>
                                <template #description>
                                    <span :style="max_min_length_password?'display:block':'display:none'" style="font-size: 11px;color: red">*มีความยาวระหว่าง 6-10 ตัวอักษร</span>
                                    <span :style="upper_password?'display:block':'display:none'" style="font-size: 11px;color: red">*ตัวอักษรภาษาอังกฤษตัวพิมพ์ใหญ่อย่างน้อย 1 ตัวอักษร</span>
                                    <span :style="lower_password?'display:block':'display:none'" style="font-size: 11px;color: red">*ตัวอักษรภาษาอังกฤษตัวพิมพ์เล็กอย่างน้อย 1 ตัวอักษร</span>
                                    <span :style="integer_password?'display:block':'display:none'" style="font-size: 11px;color: red">*ตัวอักษรเลขอย่างน้อย 1 ตัวอักษร</span>
                                </template>
                                <b-form-input
                                        id="input4"
                                        v-model="register.password"
                                        class="input-form-oscc"
                                        placeholder="ระบุรหัสผ่าน"
                                        :maxlength="10"
                                        v-on:input="check_valid_password()"
                                        type="password"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input5"
                                    class="mb-0"
                            >
                                <template #label>
                                    ยืนยันรหัสผ่าน<span class="star-red">*</span>
                                </template>
                                <b-form-input
                                        id="input5"
                                        v-model="register.re_password"
                                        class="input-form-oscc"
                                        placeholder="ระบุรหัสผ่านอีกครั้ง"
                                        :maxlength="30"
                                        type="password"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row style="margin-bottom: 25px">
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input8"
                                    class="mb-0"
                            >
                                <template #label>
                                    ตำแหน่ง<span class="star-red">*</span>
                                </template>
                                <b-form-input
                                        id="input8"
                                        v-model="register.position"
                                        class="input-form-oscc"
                                        placeholder="ระบุตำแหน่ง"
                                        type="email"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input9"
                                    class="mb-0"
                            >
                                <template #label>
                                    แผนก<span class="star-red">*</span>
                                </template>
                                <b-form-input
                                        id="input9"
                                        v-model="register.department"
                                        class="input-form-oscc"
                                        placeholder="ระบุแผนก"
                                        type="text"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="header-form-oscc mr-3 ml-3">
                            รายละเอียดอื่น ๆ
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input10"
                                    class="mb-0"
                            >
                                <template #label>
                                    คำนำหน้าชื่อ<span class="star-red">*</span>
                                </template>
                                <b-form-select v-model="register.title_th" class="form-control" id="input10" :options="options_th"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input11"
                                    class="mb-0"
                            >
                                <template #label>
                                    ชื่อ-สกุล<span class="star-red">*</span>
                                </template>
                                <b-form-input
                                        id="input11"
                                        v-model="register.name_th"
                                        class="input-form-oscc"
                                        placeholder="ระบุชื่อ-สกุล"
                                        type="text"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input12"
                                    class="mb-0"
                            >
                                <template #label>
                                    คำนำหน้าชื่อ (อังกฤษ)<span class="star-red">*</span>
                                </template>
                                <b-form-select v-model="register.title_eng" class="form-control" id="input12" :options="options_eng">
                                </b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input13"
                                    class="mb-0"
                            >
                                <template #label>
                                    ชื่อ-สกุล (อังกฤษ)<span class="star-red">*</span>
                                </template>
                                <b-form-input
                                        id="input13"
                                        v-model="register.name_eng"
                                        class="input-form-oscc"
                                        placeholder="ระบุชื่อ-สกุล (อังกฤษ)"
                                        type="text"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input14"
                                    class="mb-0"
                            >
                                <template #label>
                                    วัน/เดือน/ปีเกิด
                                </template>
                                <v-menu
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                                outlined
                                                rounded
                                                class="mt-2"
                                                dense
                                                v-model="computedDateFormatted"
                                                append-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                            v-model="register.birthDate"
                                            locale="th-th"
                                    ></v-date-picker>
                                </v-menu>
<!--                                <date-picker class="w-100" id="input14" v-model="register.birthDate" placeholder="ระบุวัน/เดือน/ปีเกิด" value-type="format" format="DD-MM-YYYY" :editable="false" :clearable="false"></date-picker>-->
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input15"
                                    class="mb-0"
                            >
                                <template #label>
                                    ที่อยู่
                                </template>
                                <b-form-input
                                        id="input15"
                                        v-model="register.address"
                                        class="input-form-oscc"
                                        placeholder="ระบุที่อยู่"
                                        type="text"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row style="margin-bottom: 25px">
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input16"
                                    class="mb-0"
                            >
                                <template #label>
                                    เบอร์โทรศัพท์
                                </template>
                                <b-form-input
                                        id="input16"
                                        v-model="register.phone_number"
                                        v-on:keypress="NumbersOnly"
                                        class="input-form-oscc"
                                        placeholder="ระบุเบอร์โทรศัพท์"
                                        :maxlength="15"
                                        type="tel"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input17"
                                    class="mb-0"
                            >
                                <template #label>
                                    เบอร์โทรศัพท์มือถือ<span class="star-red">*</span>
                                </template>
                                <b-form-input
                                        id="input17"
                                        v-model="register.mobile_phone_number"
                                        v-on:keypress="NumbersOnly"
                                        class="input-form-oscc"
                                        placeholder="ระบุเบอร์โทรศัพท์มือถือ"
                                        :maxlength="15"
                                        type="tel"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col sm="0" md="1" lg="2" xl="2"></b-col>
        </b-row>
        <b-row class="mt-5">
            <b-col sm="0" md="1" lg="2" xl="2"></b-col>
            <b-col sm="12" md="10" lg="8" xl="8">
                <div class="main-form-oscc">
                    <b-row>
                        <b-col class="header-form-oscc mr-3 ml-3">
                            ยืนยันตัวตน
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="body-form-oscc w-100" style="margin-bottom: 60px">
                            <b-form-group
                                    label-for="input18"
                                    class="mb-0"
                            >
                                <template #label>
                                    รูปภาพบัตรประจำตัวประชาชนด้านหน้า
                                </template>
                                <div class="image-upload-oscc" @click="chooseImage()" v-if="!register.id_card_image">
                                    <b-img class="icon-upload-oscc" width="70%" height="auto" :src="require('@/assets/icon/upload.svg')"></b-img>
                                    <div class="text-center">
                                        <br/>
                                        <br/>
                                        <br/>
                                        <span style="font-size: 14px;color: #4579DF">อัปโหลดรูปภาพ</span>
                                        <br>
                                        <span style="font-size: 10px;color: #7E8FB2">รองรับไฟล .jpg .jpeg หรือ .png ที่ขนาดไม่เกิน 20 MB</span>
                                    </div>
                                    <b-form-file id="upload-image"
                                                 style="display: none"
                                                 @change="uploadImage"
                                                 size="lg">
                                    </b-form-file>
                                </div>
                                <div class="image-preview-oscc" v-else>
                                    <b-img style="width: 100%;height: auto;max-height: 100%" :src="register.id_card_image">

                                    </b-img>
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6" class="body-form-oscc">
                            <b-form-group
                                    label-for="input19"
                                    class="mb-0"
                            >
                                <template #label>
                                    ตัวอย่างรูปภาพ
                                </template>
                                <div class="image-preview-oscc">
                                    <b-img style="width: 100%;height: auto;max-height: 100%" :src="require('@/assets/icon/citizencard.jpg')">

                                    </b-img>
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col sm="0" md="1" lg="2" xl="2"></b-col>
        </b-row>
        <b-row class="mt-5">
            <b-col sm="0" md="1" lg="2" xl="2"></b-col>
            <b-col sm="12" md="10" lg="8" xl="8">
                <b-button class="btn-oscc" variant="outline-secondary" @click="showAlert">ยกเลิก</b-button>
                <b-button class="btn-submit-oscc float-right" variant="primary" @click="submitData">บันทึก</b-button>
            </b-col>
            <b-col sm="0" md="1" lg="2" xl="2"></b-col>
        </b-row>
        <b-row class="footer-oscc"></b-row>
    </div>
</template>

<script>
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/th';
    export default {
        name: "register-form",
        components: {  },
        data: function () {
            return {
                register: {
                    role : 3,
                    province : '',
                    agency_code: '',
                    agency_name: '',
                    username: '',
                    password: '',
                    re_password: '',
                    email: '',
                    id_card: '',
                    position: '',
                    department: '',
                    title_th: null,
                    name_th: '',
                    title_eng: null,
                    name_eng: '',
                    birthDate: '',
                    address: '',
                    phone_number: '',
                    mobile_phone_number: '',
                    id_card_image: ''
                },
                options_th: [
                    {value: null, text: 'ระบุคำนำหน้าชื่อ', disabled: true},
                    {value: 'นาย', text: 'นาย'},
                    {value: 'นาง', text: 'นาง'},
                    {value: 'นางสาว', text: 'นางสาว'}
                ],
                options_eng: [
                    {value: null, text: 'ระบุคำนำหน้าชื่อ (อังกฤษ)', disabled: true},
                    {value: 'Mr.', text: 'Mr.'},
                    {value: 'Mrs.', text: 'Mrs.'},
                    {value: 'Miss', text: 'Miss'}
                ],
                options_role: [
                    {value: '2', text: 'สสจ.'},
                    {value: '3', text: 'โรงพยาบาล'}
                ],
                options_province : [],
                upper_password : true,
                lower_password : true,
                integer_password : true,
                max_min_length_password : true,
                //eslint-disable-next-line
                reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            }
        },
        created() {
            // let christianYear = moment().format('YYYY');
            // let buddhishYear = (parseInt(christianYear) + 543).toString();
            // let test = moment()
            //     .format('DD-MM-YYYY'.replace('YYYY', buddhishYear).replace('YY', buddhishYear.substring(2, 4)))
            //     .replace(christianYear, buddhishYear)
            // this.register.birthDate = test;
            this.$store.dispatch('MasterData/setProvince').then(res=>{
                this.options_province = res.data;
                this.options_province = [
                    {'name':'เลือกจังหวัด','code':''},
                    ...this.options_province
                ]
            })
        },
        computed: {
            isEmailValid: function() {
                return this.reg.test(this.register.email);
            },
            isIDCardValid: function() {
                let sum = 0;
                if (this.register.id_card.length != 13) return false;
                for (let i = 0; i < 12; i++) {
                    sum += parseInt(this.register.id_card.charAt(i)) * (13 - i);
                }
                let mod = sum % 11;
                let check = (11 - mod) % 10;
                if (check == parseInt(this.register.id_card.charAt(12))) {
                    return true;
                }
                return false;
            },
            computedDateFormatted() {
                if (!this.register.birthDate) return null;
                const [year, month, day] = this.register.birthDate.split("-");
                return `${day}/${month}/${parseInt(year) + 543}`;
            },
            // birthDate: {
            //     set: function(val) {
            //         this.birthDate = val;
            //     },
            //     get: function() {
            //         let christianYear = moment().format('YYYY');
            //         let buddhishYear = (parseInt(christianYear) + 543).toString();
            //         let test = moment()
            //             .format('d-M-YYYY'.replace('YYYY', buddhishYear).replace('YY', buddhishYear.substring(2, 4)))
            //             .replace(christianYear, buddhishYear)
            //         this.register.birthDate = test;
            //         return test
            //     }
            // }
        },
        methods:{
            check_valid_password() {
                if (this.register.password.length >= 6 && this.register.password.length <= 10){
                    this.max_min_length_password = false;
                }else{
                    this.max_min_length_password = true;
                }
                if (this.isUpper(this.register.password)){
                    this.upper_password = false;
                }else{
                    this.upper_password = true;
                }
                if (this.isLower(this.register.password)){
                    this.lower_password = false;
                }else{
                    this.lower_password = true;
                }
                if (this.isNumber(this.register.password)){
                    this.integer_password = false;
                }else{
                    this.integer_password = true;
                }
            },
            isUpper(str) {
                return /[A-Z]/.test(str);
            },
            isLower(str) {
                return /[a-z]/.test(str);
            },
            isNumber(str) {
                return /[0-9]/.test(str);
            },
            NumbersOnly(evt) {
                evt = (evt) ? evt : window.event;
                let charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();
                } else {
                    return true;
                }
            },
            getHospitalName(){
                if (this.register.agency_code.length == 5){
                    this.showLoading();
                    let data = {
                        code : this.register.agency_code
                    }
                    this.$store.dispatch('Auth/hospcode',data).then(res=>{
                        if (res.data){
                            this.register.agency_name = res.data.name;
                            this.hideLoading();
                        }else{
                            this.$swal.fire({
                                title: 'ไม่พบรหัสนี้',
                                confirmButtonText: 'ยืนยัน',
                            })
                        }
                    })
                }else{
                    this.register.agency_name = '';
                }
            },
            showLoading(){
                this.$loading.show({background:
                        this.$swal.fire({
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            this.$swal.showLoading()
                        }
                    })
                    ,animation : true});
            },
            hideLoading(){
                this.$loading.hide();
                this.$swal.hideLoading()
            },
            showAlert(){
                this.$swal.fire({
                    title: 'ยกเลิกการทำรายการ ?',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'ใช่',
                    cancelButtonText: 'ไม่ใช่'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$router.push('/')
                    }
                })
            },
            chooseImage(){
                document.getElementById('upload-image').click();
            },
            uploadImage:function(event){
                let input = event.target;
                let file = input.files[0];
                if(file){
                    //อัพโหลดรูป
                    let formData = new FormData();
                    //ไฟล์รูป
                    formData.append('file',file);
                    //ชื่อ folder ที่จะเก็บ
                    formData.append('folder','register');
                    this.$store.dispatch("Auth/getPathFile",formData).then(path_file=>{
                        this.register.id_card_image = path_file.data;
                    })
                }
            },
            submitData(){
                let validator = this.validate();
                if (validator){
                    this.$swal.fire({
                        icon: 'error',
                        title: validator,
                    })
                }else{
                    this.showLoading();
                    let data = {
                        birth_date : this.register.birthDate ? this.register.birthDate : "",
                        image : this.register.id_card_image ? this.register.id_card_image.substr(this.register.id_card_image.length - 14) : '',
                        id_card : this.register.id_card ? this.register.id_card : '',
                        name : this.register.username ? this.register.username : '',
                        email : this.register.email ? this.register.email : '',
                        password : this.register.password ? this.register.password : '',
                        agency_code : this.register.agency_code ? this.register.agency_code : '',
                        agency_name : this.register.agency_name ? this.register.agency_name : '',
                        position : this.register.position ? this.register.position : '',
                        department : this.register.department ? this.register.department : '',
                        title : this.register.title_th ? this.register.title_th : '',
                        full_name : this.register.name_th ? this.register.name_th : '',
                        title_eng : this.register.title_eng ? this.register.title_eng : '',
                        full_name_eng : this.register.name_eng ? this.register.name_eng : '',
                        home_phone : this.register.phone_number ? this.register.phone_number : '',
                        mobile_phone : this.register.mobile_phone_number ? this.register.mobile_phone_number : '',
                        address : this.register.address ? this.register.address : '',
                        role : this.register.role ? this.register.role : '',
                        province : this.register.province ? this.register.province : '',
                    }
                    this.$store.dispatch('Auth/register',data).then(res=>{
                        if (res.data.token){
                            this.hideLoading();
                            this.$swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'สมัครสำเร็จ',
                                showConfirmButton: false,
                                timer: 3000
                            })
                            this.$router.push('/');
                        }else{
                            this.$swal.fire({
                                position: 'top-end',
                                icon: 'error',
                                title: res.message,
                                showConfirmButton: false,
                                timer: 3000
                            })
                        }
                    }).catch(()=>{
                        this.hideLoading();
                    })
                }
            },
            validate(){
                let self = this;
                let arr_validate = [
                    'mobile_phone_number','name_eng','title_eng','name_th',
                    'title_th','department','position','id_card',
                    'email','re_password','password',
                    'agency_code'
                ];
                let arr_validate_th = [
                    'เบอร์โทรศัพท์มือถือ','ชื่อ (อังกฤษ)','คำนำหน้าชื่อ (อังกฤษ)','ชื่อ',
                    'คำนำหน้าชื่อ','แผนก','ตำแหน่ง','รหัสบัตรประชาชน',
                    'อีเมล','รหัสผ่านอีกครั้ง','รหัสผ่าน',
                    'รหัสหน่วยงาน'
                ];
                let checker = 1;
                let failed_key = '';
                Object.keys(this.register).forEach(function(key){
                    if (!self.register[key] && arr_validate.includes(key) && checker == 1){
                        checker = 0;
                        failed_key = key;
                    }
                });
                if (this.register.role == 2 && !this.register.province) return 'กรุณาเลือกจังหวัด';
                else if (this.max_min_length_password || this.upper_password || this.lower_password || this.max_min_length_password) return 'รูปแบบรหัสผ่านไม่ถูกต้อง';
                else if (checker && this.register['password'] && this.register['re_password'] && !this.register['password'].includes(this.register['re_password'])) return 'รหัสผ่านไม่ตรงกัน';
                else if (checker && this.register['email'] && !this.isEmailValid) return 'รูปแบบอีเมลไม่ถูกต้อง';
                else if (checker && this.register['id_card'] && !this.isIDCardValid) return 'รูปแบบรหัสบัตรประชาชนไม่ถูกต้อง';
                else if (checker && !this.register['id_card_image'])return 'กรุณาใส่รูปบัตรประชาชน';
                else if (checker) return false;
                else return 'กรุณาระบุ ' + arr_validate_th[arr_validate.indexOf(failed_key)];
            }
        }
    }
</script>

<style scoped>
    .main-form-oscc{
        background: #FFFFFF;
        border: 0.5px solid #A8A8A8;
        box-sizing: border-box;
        box-shadow: 1px 1px 9px 1px rgba(174, 194, 217, 0.3);
        border-radius: 5px;
    }
    .header-form-oscc{
        background: #DEE5FB;
        border-radius: 5px 5px 0 0;
        font-size: 18px;
        padding-top: 5px;
        padding-bottom: 2px;
    }
    .body-form-oscc{
        padding-left: 7%;
        padding-right: 6%;
        margin-top: 13px;
    }
    .star-red{
        color: red;
    }
    .input-form-oscc{
        background: #FFFFFF;
        border: 1px solid #7F98B5;
        box-sizing: border-box;
        border-radius: 20px;
    }
    input.input-mx {
        border-radius: 20px;
    }
    .image-upload-oscc{
        width: 100%;
        height: 240px;
        border: 1px solid #6392EC;
        box-sizing: border-box;
        border-radius: 5px;
        cursor: pointer;
    }
    .image-preview-oscc{
        width: 100%;
        height: 240px;
        border: 1px solid #6392EC;
        box-sizing: border-box;
        border-radius: 5px;
    }
    .icon-upload-oscc{
        position:relative;
        top:30%;
        left:50%;
        transform:translate(-50%, -50%);
    }
    .footer-oscc{
        margin-bottom: 5%;
    }
    .btn-oscc{
        font-size: 16px;
        box-shadow: 1px 1px 10px rgba(56, 84, 138, 0.28);
        border-radius: 24px;
        padding: 10px 50px 10px 50px;
    }
    .btn-submit-oscc{
        font-size: 16px;
        box-shadow: 1px 1px 10px rgba(56, 84, 138, 0.28);
        border-radius: 24px;
        padding: 10px 100px 10px 100px;
    }
    b-col{
        margin-bottom: 20px;
    }
</style>
